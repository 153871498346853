const cutNumber = require('./cutNumber');

const COVER_TYPES = new Set(['cover', 'grid']);

/**
 * Helper for generate url for image-resize service
 * @param {string} src
 * @param {string} layout
 * @param {Object} dynamic
 * @param {string} imageResizeApiService
 * @returns {string}
 */
const getImageResizeUrl = (
  src,
  layout = 'image_desktop',
  dynamic = {},
  imageResizeApiService
) => {
  const {
    type,
    transform = {},
  } = dynamic;
  const isCover = COVER_TYPES.has(type);
  const layoutTransform = transform[layout];

  if (!layoutTransform) return src;

  const {
    scale,
    wrap_width: width,
    wrap_height: height,
    offset: {
      x,
      y,
    } = {},
  } = layoutTransform;
  const indData = {
    x: cutNumber(x) || 0,
    y: cutNumber(y) || 0,
    scale: cutNumber(scale) || 1,
    width: cutNumber(width),
    height: cutNumber(height),
  };

  const containSrc = `${imageResizeApiService}?url=${src}&height=${indData.height}`;

  return isCover ? `${containSrc}&width=${indData.width}&scale=${indData.scale}&x=${indData.x}&y=${indData.y}` : containSrc;
};

module.exports = getImageResizeUrl;
