import isEmpty from 'lodash/isEmpty';

import dom from '../../../wrapper/DomWrapper';

import { IMAGE_CONTAINER_SELECTOR, IMAGE_INNER_BORDER_SELECTOR, IMAGE_SELECTOR } from './constants';

/**
 * Util for setting inline border radius to an image container
 * Shows element after calculation
 * @param {Element} image
 */
export const setImageBorderRadius = (image) => {
  if (!image) return;

  const container = image.closest(IMAGE_CONTAINER_SELECTOR);

  if (!container || !container.dataset?.settings) return;

  const settings = JSON.parse(container.dataset?.settings);

  if (isEmpty(settings)) return;

  const { borderRadius } = settings;

  if (!borderRadius) return;

  const innerBorder = dom.getElement(IMAGE_INNER_BORDER_SELECTOR, container);
  const containerWidth = dom.getElementWidth(container);
  const containerHeight = dom.getElementHeight(container);
  const borderRadiusInPx = Math.min(containerWidth, containerHeight) * (borderRadius / 100);

  dom.updateStyle(container, { borderRadius: `${borderRadiusInPx}px` });
  if (innerBorder) dom.updateStyle(innerBorder, { borderRadius: `${borderRadiusInPx}px` });

  // TODO make without recalculation on load
  if (!borderRadiusInPx) {
    setTimeout(() => {
      // eslint-disable-next-line no-use-before-define
      recalculateCurrentImageBorder(image);
    }, 500);
  } else {
    dom.showOpacity(container);
  }
};

/**
 * Util for rechecking border size (used on resize event)
 */
export const recalculateImageBorder = () => {
  const elImages = dom.getCollection(IMAGE_SELECTOR);

  if (isEmpty(elImages)) return;

  elImages.forEach((image) => {
    setImageBorderRadius(image);
  });
};

/**
 * Util for rechecking specific image border
 */
export const recalculateCurrentImageBorder = (image) => {
  if (!image) return;

  setImageBorderRadius(image);
};
