import {
  DESKTOP,
  LAPTOP,
  PHONE,
  QUAD,
  TABLET,
  ULTRA,
} from '../../../device';

export const IMAGE_V2_CLASS_NAME = 'is-image-v2';
export const IMAGE_RESIZE_CLASS_NAME = 'is-image-resize';
export const DEVICE_SIZES = { // also used in blog
  [PHONE]: [0, 320],
  [TABLET]: [321, 1024],
  [LAPTOP]: [1025, 1366],
  [DESKTOP]: [1367, 1920],
  [QUAD]: [1921, null],
};
export const FLUID_DEVICE_SIZES = {
  [QUAD]: [1921, 2560],
  [ULTRA]: [2561, null],
};
