import isFunction from 'lodash/isFunction';

import getStateValue from '../../../../../helpers/getStateValue';
import dom from '../../../../../wrapper/DomWrapper';
import { isNumericId } from '../ecwidProvider/utils';

const staticCdnService = getStateValue('apiUrls.staticCdnService');

class EcwidCart {
  constructor(context) {
    this.context = context;
    this.storageName = 'stand-alone-checkout-cart';
    this.onChangeActions = new Set();
    this.connectStorage();
  }

  // eslint-disable-next-line class-methods-use-this
  get dash() {
    try {
      return CartFunctions;
    } catch {
      throw new Error('Wrong environment, CartFunctions not found');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  set dash(value) {
    throw new Error('dash is read only property');
  }

  // eslint-disable-next-line class-methods-use-this
  get storage() {
    return dom.window.localStorage.getItem(this.storageName);
  }

  // eslint-disable-next-line class-methods-use-this
  set storage(value) {
    try {
      dom.window.localStorage.setItem(this.storageName, JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  }

  init = async () => {
    try {
      const timestamp = Date.now();
      const EcomDashUrlsResponse = await window.fetch(`${staticCdnService}/bundle/EcomDashUrls.json?timestamp=${timestamp}`);
      const EcomDashUrls = await EcomDashUrlsResponse?.json();

      if (!EcomDashUrls) return;

      const { ecomDashCartModule } = EcomDashUrls;

      // eslint-disable-next-line no-undef
      await StandaloneCheckoutLoader.loadScripts(ecomDashCartModule);
      dom.window.StandAloneCheckout.init({
        data: {
          storeId: this.context.storeId,
          configuration: {
            locale: 'en-US',
            region: 'US',
          },
        },
        selector: 'mfe-docking-container',
        theme: {
          palette: {
            primary: {
              main: '#0082ED',
              light: '#26B7FF',
              dark: '#0050C7',
              _isBasePalette: true,
            },
            text: {
              primary: '#000000',
              secondary: '#FFFFFF',
            },
            background: {
              paper: '#dddddd',
              default: '#D3D3D3',
            },
          },
          typography: {
            fontFamily: '\'Times New Roman\', serif',
            htmlFontSize: 16,
            fontSize: 14,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  connectStorage = () => {
    // fix for missing storage in cart-functions.js script
    if (this.storage) return;

    this.storage = [];
  };

  // eslint-disable-next-line class-methods-use-this
  checkProductId = (id) => {
    if (isNumericId(id)) throw new Error('Incorrect product id type');
  };

  itemAdd = async (
    productId,
    quantity = 1,
    options,
    cb = () => null
  ) => {
    this.checkProductId(productId);

    const cart = await this.getCart();

    cart.addToCart(productId, quantity);

    if (isFunction(cb)) cb(true);

    const count = await this.getTotalQuantity();
    let items;

    try {
      items = JSON.parse(this.storage);
    } catch (error) {
      console.error(error);
    }

    this.onChangeActions.forEach((func) => {
      if (!isFunction(func)) return;

      func({
        productsQuantity: count || 0,
        items: items || [],
      });
    });
  };

  itemGetQuantity = async (id) => {
    this.checkProductId(id);

    let items = [];

    try {
      items = JSON.parse(this.storage) || [];
    } catch (error) {
      console.error(error);
    }

    if (items.length === 0) return 0;

    const product = items.find((el) => el.product_id === id);

    return product?.quantity || 0;
  };

  getCart = async () => {
    await this.context.isReady();

    return this.dash;
  };

  getTotalQuantity = async () => {
    const cart = await this.getCart();

    return cart.getCartProductsCount();
  };

  openCart = async () => {
    const cart = await this.getCart();

    await cart.openCart();
  };

  onChange = {
    add: async (cb) => {
      if (!isFunction(cb)) return;

      await this.context.isReady();
      this.onChangeActions.add(cb);
    },
    clear: async () => {
      await this.context.isReady();
      this.onChangeActions.clear();
    },
  };
}

export default EcwidCart;
