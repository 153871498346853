const isEmpty = require('lodash/isEmpty');
const getFileExtension = require('./getFileExtension');

const NOT_RESIZABLE_TYPES = new Set(['svg', 'gif']);

/**
 * Util for check if image can be resizable
 * @param {string} src
 * @returns {boolean} isImageResizable
 */
const checkImageResizable = (src) => {
  const fileExtension = getFileExtension(src);

  return !isEmpty(fileExtension) && !NOT_RESIZABLE_TYPES.has(fileExtension);
};

module.exports = checkImageResizable;
